<script setup>
import {ref} from 'vue';
import route from 'ziggy';
import Dropdown from '@/Components/Dropdown.vue';
import DesktopPublicMenu from '@/Components/FrontEnd/Menu/DesktopPublicMenu.vue';
import DesktopGuestMenu from '@/Components/FrontEnd/Menu/DesktopGuestMenu.vue';
import DesktopUserDropdownMenu from '@/Components/FrontEnd/Menu/DesktopUserDropdownMenu.vue';
import MobileUserDropdownMenu from '@/Components/FrontEnd/Menu/MobileUserDropdownMenu.vue';
import SplitFamilyCircle from '@/Components/Icons/SplitFamilyCircle.vue';
import {router} from '@inertiajs/vue3';

const open = ref(false)
const logout = () => {
    router.post(route('logout'));
};
</script>

<template>
    <div class="w-full bg-base-100 text-base-content">
        <div class="flex flex-col max-w-screen-xl px-8 md:pb-4 mx-auto md:items-center md:justify-between md:flex-row">
            <div class="flex flex-row items-center justify-between py-6 md:py-0">
                <div class="relative md:mt-4">
                    <!-- Not using route('home') to reduce Largest Contentful Paint -->
                    <a :href="$page.props.homeUrl"
                       class="text-lg relative z-50 font-bold tracking-widest rounded-lg focus:outline-none focus:shadow-outline">
                        {{ $page.props.appName }}
                    </a>
                    <SplitFamilyCircle class="h-11 z-40 absolute -top-2 -left-2.5 text-secondary"/>
                </div>
                <!-- Mobile menu icon -->
                <button :aria-label="$t('Menu')" class="rounded-lg md:hidden focus:outline-none focus:shadow-outline" @click="open = !open">
                    <svg fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6">
                        <path v-if="!open" fill-rule="evenodd"
                              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                              clip-rule="evenodd"></path>
                        <path v-if="open" fill-rule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clip-rule="evenodd"></path>
                    </svg>
                </button>
            </div>
            <!-- Menu -->
            <nav
                    class="md:h-auto flex flex-col flex-grow md:items-center md:flex md:justify-end md:flex-row origin-top duration-300 lg:min-h-[64px]"
                    :class="{ 'transform md:transform-none scale-y-0 h-0': !open, 'h-full': open }"
            >
                <DesktopPublicMenu/>

                <!-- Teams Dropdown -->
                <Dropdown v-if="$page.props.jetstream.hasTeamFeatures"
                          class="px-4 py-2 mt-2 text-sm bg-transparent rounded-lg md:mt-8 md:ml-4 hover:text-accent focus:outline-none focus:shadow-outline"
                          align="right" width="60">
                    <template #trigger>
                    <span class="inline-flex rounded-md">
                        <button type="button"
                                class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md bg-white hover:bg-gray-50 hover:text-accent focus:outline-none focus:bg-gray-50 active:bg-gray-50 transition">
                                   {{
                            $page.props.auth.user.current_team ? $page.props.auth.user.current_team.name:$t('No Team')
                            }}
                            <svg
                                    class="ml-2 -mr-0.5 h-4 w-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                            >
                                <path fill-rule="evenodd"
                                      d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                      clip-rule="evenodd"/>
                            </svg>
                        </button>
                    </span>
                    </template>

                    <template #content>
                        <div class="w-60">
                            <!-- Team Management -->
                            <template v-if="$page.props.jetstream.hasTeamFeatures">
                                <div class="block px-4 py-2 text-xs text-gray-400">
                                    Manage Team
                                </div>

                                <!-- Team Settings -->
                                <DropdownLink v-if="$page.props.auth.user.current_team"
                                              :href="route('teams.show', $page.props.auth.user.current_team)">
                                    Team Settings
                                </DropdownLink>

                                <DropdownLink v-if="$page.props.jetstream.canCreateTeams"
                                              :href="route('teams.create')">
                                    Create New Team
                                </DropdownLink>

                                <div class="border-t border-gray-100"/>

                                <!-- Team Switcher -->
                                <div class="block px-4 py-2 text-xs text-gray-400">
                                    Switch Teams
                                </div>

                                <template v-for="team in $page.props.auth.user.all_teams" :key="team.id">
                                    <form @submit.prevent="switchToTeam(team)">
                                        <DropdownLink as="button">
                                            <div class="flex items-center">
                                                <svg
                                                        v-if="team.id === $page.props.auth.user.current_team_id"
                                                        class="mr-2 h-5 w-5 text-green-400"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        stroke="currentColor"
                                                        viewBox="0 0 24 24"
                                                >
                                                    <path
                                                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                </svg>
                                                <div>{{ team.name }}</div>
                                            </div>
                                        </DropdownLink>
                                    </form>
                                </template>
                            </template>
                        </div>
                    </template>
                </Dropdown>

                <div v-if="$page.props.auth.user">
                    <!-- Desktop User Settings Dropdown -->
                    <DesktopUserDropdownMenu :logout="logout"/>
                    <!-- Mobile User Settings Menu Items -->
                    <MobileUserDropdownMenu :logout="logout"/>
                </div>
                <template v-else>
                    <!-- Guest User -->
                    <DesktopGuestMenu/>
                </template>
            </nav>
        </div>
    </div>
</template>
