<template>
    <div v-if="notification" ref="targetElement"
         class="p-4 my-4 mx-4 lg:mx-8 border rounded-lg transition ease-out transition-opacity"
         :class="[showClass, isError ? 'border-red-300 bg-red-50 dark:bg-red-200' : 'border-green-300 bg-green-50 dark:bg-green-200']"
         role="alert">
        <div class="flex items-center">
            <svg aria-hidden="true" class="w-5 h-5 mr-2"
                 :class="isError ? 'text-red-900 dark:text-red-800' : 'text-green-900 dark:text-green-800'"
                 fill="currentColor"
                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clip-rule="evenodd"></path>
            </svg>
            <span class="sr-only">Info</span>
            <h3 class="text-lg font-medium"
                :class="isError ? 'text-red-900 dark:text-red-800' : 'text-green-900 dark:text-green-800'">
                {{ isError ? 'Error' : 'Success' }}</h3>
            <button v-if="!link" type="button" class="ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex h-8 w-8"
                    :class="isError ? 'bg-red-50 dark:bg-red-100 text-red-500 focus:ring-red-400 hover:bg-red-200 dark:hover:bg-red-300' : 'bg-green-50 dark:bg-green-100 text-green-500 focus:ring-green-400 hover:bg-green-200 dark:hover:bg-green-300'"
                    data-dismiss-target="#alert-border-2"
                    aria-label="Close" @click="hide">
                <span class="sr-only">Close</span>
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"></path>
                </svg>
            </button>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div class="mt-2 text-sm"
             :class="isError ? 'text-red-900 dark:text-red-800' : 'text-green-900 dark:text-green-800'"
             v-html="typeof notification === 'string' ? $t(notification) : notification"></div>
        <!--eslint-enable-->
        <div v-if="link" class="flex mt-4">
            <a :href="link"
               class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center"
               :class="isError ? 'bg-red-900 hover:bg-red-800 focus:ring-red-300 dark:bg-red-800 dark:hover:bg-red-900' : 'bg-green-900 hover:bg-green-800 focus:ring-green-300 dark:bg-green-800 dark:hover:bg-green-900'"
            >
                <svg aria-hidden="true" class="-ml-0.5 mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                    <path fill-rule="evenodd"
                          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                          clip-rule="evenodd"></path>
                </svg>
                View more
            </a>
            <button type="button" class="bg-transparent border hover:text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:text-white"
                    :class="isError ? 'text-red-900 border-red-900 hover:bg-red-900 focus:ring-red-300 dark:border-red-800 dark:text-red-800' : 'text-green-900 border-green-900 hover:bg-green-900 focus:ring-green-300 dark:border-green-800 dark:text-green-800'"
                    aria-label="Close"
                    @click="hide">
                Dismiss
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InlineAlert',
    props: {
        timeout: {
            type: Number,
            default: 500,
        },
        link: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            show: true,
        }
    },
    computed: {
        notification() {
            return this.isError ? this.$page.props.flash.errors : this.$page.props.flash.status;
        },
        isError() {
            return this.$page.props.flash.errors;
        },
        icon() {
            return this.isError ? 'exclamation-circle' : 'check-circle';
        },
        showClass() {
            return this.show ? 'opacity-100 duration-' + this.timeout : 'opacity-0 duration-' + this.timeout;
        },
    },
    methods: {
        hide() {
            this.show = false;
            setTimeout(() => {
                this.$refs.targetElement.classList.add('hidden')
                // Clear session
                this.$page.props.flash = {};
            }, this.timeout / 2);
        }
    },
}
</script>
