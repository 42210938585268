<script setup>
import route from 'ziggy';

const props = defineProps({
    title: {type: String, default: null},
    routeName: {type: String, default: null},
    routeParameters: {type: Object, default: null},
    activeRoutes: {type: Array, default: () => []},
    isButton: {type: Boolean, default: false},
});

const active = route().current(props.routeName) || (props.activeRoutes && props.activeRoutes.filter((routeName) => route().current(routeName) === true).length > 0);
</script>

<template>
    <button v-if="isButton"
            class="block px-4 py-2 mt-2 text-sm bg-transparent rounded-lg md:mt-8 md:ml-4 hover:text-gray-900 focus:outline-none focus:shadow-outline"
            :class="{'text-secondary-500 hover:text-secondary-300': active}">
        <slot>
            {{ $t(title) }}
        </slot>
    </button>

    <a v-else
       class="block px-4 py-2 mt-2 text-sm bg-transparent rounded-lg md:mt-8 md:ml-4 hover:text-gray-900 focus:outline-none focus:shadow-outline"
       :class="{'text-secondary-500 hover:text-secondary-300': active}"
       :href="route(routeName, routeParameters)">
        <slot>
            {{ $t(title) }}
        </slot>
    </a>
</template>
