<script setup>
import {onMounted, ref, watch} from 'vue';
import FrontendNavBar from '@/Components/FrontEnd/FrontendNavBar.vue';
import FrontendFooter from '@/Components/FrontEnd/FrontendFooter.vue';
import InlineAlert from '@/Components/Shared/InlineAlert.vue';
import AOS from 'aos';
import {Head, usePage} from '@inertiajs/vue3';

defineProps({
    title: {type: String, default: ''},
});

onMounted(() => {
    AOS.init();
})

// Alert watcher - see https://laracasts.com/discuss/channels/inertia/watch-props-vue-3-inertia-composition-api
const page = usePage();
const alert = ref(null);
watch(page.props, function (val) {
    if (val?.flash && alert.value) {
        alert.value.show = true;
    }
}, {
    immediate: true,
    deep: true,
});
</script>

<template>
    <div class="min-h-screen">
        <Head :title="title"/>

        <header class="sticky top-0 z-50">
            <FrontendNavBar/>
        </header>

        <!-- Page Heading -->
        <header v-if="$slots.header">
            <slot name="header"/>
        </header>

        <!-- Page Content -->
        <main>
            <InlineAlert ref="alert"/>
            <slot/>
        </main>

        <footer class="sticky top-[100vh]" style="background-color: rgba(37, 38, 65, 1);">
            <FrontendFooter/>
        </footer>
    </div>
</template>
